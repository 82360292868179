



































import mixins from 'vue-typed-mixins'
import Newsletter from '@/builder/sections/section/newsletter/mixins/Newsletter'

export default mixins(Newsletter).extend({
  name: 'Newsletter8'
})
